<template>
  <FinalField ref="field" :name="name" :validate="validate">
    <template v-slot="props">
      <div class="form-col">
        <label v-if="label">{{label}}</label>
        <DurationInput
            ref="input"
            :model-value="props.value"
            @update:modelValue="props.change"
        />

        <FieldError v-if="!suppressError" :name="name" />
      </div>
    </template>
  </FinalField>
</template>

<script>
import {FinalField} from "vue-final-form";
import FieldError from "@/components/form/FieldError";
import DurationInput from "@/components/ui/DurationInput";

export default {
  components: {
    DurationInput,
    FinalField,
    FieldError,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    validate: {
      type: [Function, Array],
      required: false,
    },

    suppressError: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    clear() {
      this.$refs.input.clear();
    },
  },
}
</script>

<style scoped>

</style>
