<template>
  <div class="flex">
    <div class="relative mr-2">
      <input
          class="form-input"
          type="number"
          min="0"
          placeholder="0"
          v-model="hours"
      />
      <div class="trailing-text">hr</div>
    </div>
    <div class="relative">
      <input
          class="form-input"
          type="number"
          placeholder="0"
          min="0"
          v-model="minutes"
      />
      <div class="trailing-text">min</div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },

  props: {
    /**
     * component's v-model
     */
    modelValue: {
      required: false,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      hours: null,
      minutes: null,
    };
  },

  watch: {
    hours() {
      this.emitEvent();
    },

    minutes(val) {
      if (val > 60) {
        this.minutes = val % 60;
        this.hours = (this.hours || 0) + Math.floor(val / 60);
      }

      this.emitEvent();
    },
  },

  methods: {
    emitEvent() {
      this.$emit('update:modelValue', (this.hours || 0) * 60 + (this.minutes || 0));
    },

    clear() {
      this.hours = null;
      this.minutes = null;
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.form-input {
  @apply pr-10;
}
.trailing-text {
  @apply absolute text-2sm text-graphite-800;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
